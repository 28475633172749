<template>
  <el-container v-loading="loading">
    <el-header><Header></Header></el-header>
    <el-container>
      <el-main>
        <el-card class="box-card">
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane
              v-for="route in sidebarRouters[0].children"
              :key="route.id"
              :name="route.url"
              :label="route.meta.title"
              :target="route.target"
            ></el-tab-pane>
          </el-tabs>

          <router-view />
        </el-card>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "./components/Apper/Header.vue";

export default {
  data() {
    return {
      loading: false,
      activeName: this.$route.path,
    };
  },
  computed: {
    ...mapGetters(["sidebarRouters"]),
  },
  components: {
    Header,
  },
  methods: {
    handleClick(tab) {
      if (this.$route.path == tab.name) {
        return;
      }
      if (tab.$attrs.target) {
        window.open(tab.name);
      } else {
        this.$router.push(tab.name);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
